















import { Component, Vue } from 'vue-property-decorator'

import SubscriptionsHistoryTable from '@/partials/tables/Events/Subscriptions/History/index.vue'

@Component({
  components: {
    SubscriptionsHistoryTable
  }
})
export default class EventSubscriptionsHistoryIndividual extends Vue {

}
