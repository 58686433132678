






















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class TablePagination extends Vue {
  @Prop({ required: true, type: Number }) total!: number
  @Prop({ type: Number }) selectedSize!: number
  pageSizes: any = [20, 50, 100]

  onPageChange (pagination: Pagination) {
    this.$emit('change', pagination)
  }

  created () {
    this.pageSizes = this.pageSizes.map((size: number) => {
      if (size === this.selectedSize) {
        return { value: size, selected: true }
      } else {
        return size
      }
    })
  }
}
