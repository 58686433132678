














































































import { Component, Vue, Prop } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'

import TablePagination from '@/partials/tables/Pagination.vue'

@Component({
  components: {
    TablePagination
  }
})
export default class EventSubscriptionsHistoryTable extends Vue {
  @Prop({ required: true, type: String }) eventId!: string
  @Prop({ type: String }) subscriptionId!: string
  filteredData: Array<any> = []
  data: Array<any> = []
  dataTotal = 0
  isDetailModalOpened = false
  selectedHistory = {
    details: ''
  }

  created () {
    if (this.isIndividualHistory) {
      this.fetch()
    }
  }

  fetch (page = 1, perPage = 20) {
    const requestUrl = this.isIndividualHistory ? `/event/${this.eventId}/subscription/${this.subscriptionId}/logs` : `/event/${this.eventId}/subscriptions/log`

    const perPageSnake = snakeCaseKeys({ perPage })

    axios.get(requestUrl, {
      params: {
        'page[number]': page,
        ...perPageSnake
      }
    })
      .then(response => {
        this.filteredData = this.data = camelCaseKeys(response.data.data, { deep: true })
        this.dataTotal = response.data.total
      })
  }

  onPageChange (pagination: Pagination) {
    this.fetch(pagination.page, pagination.length)
  }

  get isIndividualHistory () {
    return !!this.subscriptionId
  }
}
